var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"table-indicadores",attrs:{"multi-sort":"","dense":"","headers":_vm.headers,"items":_vm.items,"options":{ itemsPerPage: 10 },"headerProps":{ sortByText: 'Ordenar Por' },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [(_vm.traducaoIndicadores[h.value] != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.traducaoIndicadores[h.value]))])]):_c('span',[_vm._v(_vm._s(h.text))])]}}}),{key:"item.empresa",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.empresa.name))+" ")]}},{key:"item.qlp",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.qlp, item.metas.qlp != undefined ? item.metas.qlp : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.qlp))+" ")])]}},{key:"item.pla",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.pla, item.metas.pla != undefined ? item.metas.pla : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pla != '-' ? item.pla : '-'))+" "+_vm._s(item.pla != '-' ? ' %' : '')+" ")])]}},{key:"item.pmea",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.pmea, item.metas.pmea != undefined ? item.metas.pmea : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pmea))+" ")])]}},{key:"item.pat1",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.pat1, item.metas.pat1 != undefined ? item.metas.pat1 : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pat1))+" ")])]}},{key:"item.pat2",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.pat2, item.metas.pat2 != undefined ? item.metas.pat2 : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pat2))+" ")])]}},{key:"item.pat3",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.pat3, item.metas.pat3 != undefined ? item.metas.pat3 : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pat3))+" ")])]}},{key:"item.pat4",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.classIndicador(item.pat4, item.metas.pat4 != undefined ? item.metas.pat4 : null)},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pat4))+" ")])]}},{key:"item.metas",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",attrs:{"min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.openMetasDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"720"},model:{value:(_vm.metasDialog),callback:function ($$v) {_vm.metasDialog=$$v},expression:"metasDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_c('h4',[_vm._v(" "+_vm._s(_vm.itemSelected.empresa.name)+" - "+_vm._s(_vm.itemSelected.competencia)+" ")])]),_c('v-card-text',[_c('v-simple-table',{staticClass:"table-indicadores",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Indicador")]),_c('th',[_vm._v("Valor")]),_c('th',[_vm._v("Meta")]),_c('th',[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.itemSelected.indicadores),function(valor,indicador){return _c('tr',{key:indicador},[(_vm.traducaoIndicadores[indicador] != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('td',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(indicador))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toUpperCase")(_vm.traducaoIndicadores[indicador])))])]):_c('td',[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(indicador))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(valor != null || valor != undefined ? valor : '-'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(_vm.itemSelected.metas[indicador] === undefined ? '-' : _vm.itemSelected.metas[indicador]))+" ")]),_c('td',[_c('ArrowMetaDashboard',{attrs:{"indicador":valor != null || valor != undefined ? valor : null,"meta":_vm.itemSelected.metas[indicador] != undefined ? _vm.itemSelected.metas[indicador] : null}})],1)],1)}),0)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.metasDialog = false}}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }